// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.
@import "_variables_theme";
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$calculadora-inbound-primary: mat.define-palette($md-mecate);
$calculadora-inbound-accent: mat.define-palette($md-mecate, A200, A100, A400);
// The warn palette is optional (defaults to red).
$calculadora-inbound-warn: mat.define-palette(mat.$red-palette);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$calculadora-inbound-theme: mat.define-light-theme(
    (
        color: (
            primary: $calculadora-inbound-primary,
            accent: $calculadora-inbound-accent,
            warn: $calculadora-inbound-warn,
        ),
    )
);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($calculadora-inbound-theme);

/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;
html,
body {
    height: 100%;
}

body {
    margin: 0;
}
